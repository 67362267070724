import React, { useReducer, useEffect } from "react";
import "../App.css";
import Navigation from "./Navigation";
import Header from "./Header";
import Movie from "./Movie";
import Search from "./Search";


const MOVIE_API_URL = `https://www.omdbapi.com/?s=man&apikey=${process.env.REACT_APP_IMDB_KEY_CODE}`;


const initialState = {
  loading: true,
  movies: [],
  errorMessage: null
};


const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH_MOVIES_REQUEST":
      return {
        ...state,
        loading: true,
        errorMessage: null
      };
    case "SEARCH_MOVIES_SUCCESS":
      return {
        ...state,
        loading: false,
        movies: action.payload
      };
    case "SEARCH_MOVIES_FAILURE":
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};



const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
    
        fetch(MOVIE_API_URL)
            .then(response => response.json())
            .then(jsonResponse => {
        
            dispatch({
                type: "SEARCH_MOVIES_SUCCESS",
                payload: jsonResponse.Search
            });
        });
    }, []);

    const search = searchValue => {
        dispatch({
        type: "SEARCH_MOVIES_REQUEST"
        });
    
        fetch(`https://www.omdbapi.com/?s=${searchValue}&apikey=${process.env.REACT_APP_IMDB_KEY_CODE}`)
        .then(response => response.json())
        .then(jsonResponse => {
            if (jsonResponse.Response === "True") {
            dispatch({
                type: "SEARCH_MOVIES_SUCCESS",
                payload: jsonResponse.Search
            });
            } else {
            dispatch({
                type: "SEARCH_MOVIES_FAILURE",
                error: jsonResponse.Error
            });
          }
        });
      };

    const { movies, errorMessage, loading } = state;

    return (
    <div className="App">
      <Navigation/>
      <Header text="HOOKED MOVIE SEARCH" />
      <Search search={search} />
      <p className="App-intro">Here are some of our favourite movies<br /> Search to discover more</p>
      <div className="movies">
        {loading && !errorMessage ? (
          <span>loading... </span>
        ) : errorMessage ? (
          <div className="errorMessage">{errorMessage}</div>
        ) : (
          movies.map((movie, index) => (
            <Movie key={`${index}-${movie.Title}`} movie={movie} />
          ))
        )}
      </div>
    </div>
  );
};

export default App;
